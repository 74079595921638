import { CustomUrlBlock as CustomUrlBlockGraphQlType } from "common/types/graphql"
import {
  CustomUrlBlock as CommonCustomUrlBlock,
  CustomUrlBlockProps,
} from "common/components/CustomUrlBlock"

export type CustomUrlBlockFrontendBlock = CustomUrlBlockProps &
  Omit<CustomUrlBlockGraphQlType, "image">

export const CustomUrlBlock = (props: CustomUrlBlockFrontendBlock) => {
  return <CommonCustomUrlBlock {...props} />
}
